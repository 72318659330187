import React from "react";
import "./operatorGuideHeader.module.css";
import { getBaseColorVar, useI18n } from "compass-commons";
import { IncidentIcon } from "dms-lib";
import { IncidentHeaderDescription } from "../../../model/incident/IncidentHeaderDescription";
import OperatorGuideBadge from "./OperatorGuideBadge";
import DurationCounter from "./DurationCounter";

interface OperatorGuideHeaderProps {
  incidentHeaderDescription: IncidentHeaderDescription;
  className?: string;
}

const OperatorGuideHeader = (props: OperatorGuideHeaderProps): JSX.Element => {
  const { incidentHeaderDescription: incident, className } = props;
  const elementClassName = `${
    (className && `${className} `) || ""
  }operator-guide-header`;

  const { t: translate } = useI18n();

  return (
    <div
      className={elementClassName}
      style={{ borderLeftColor: getBaseColorVar(incident.priority) }}
    >
      <div className="operator-guide-header__main">
        <OperatorGuideBadge triggerType={incident.triggerType}>
          <IncidentIcon
            src={incident.iconPath}
            priority={incident.priority}
            size="small"
            className="incident-fit-icon"
          />
        </OperatorGuideBadge>
        <div className="operator-guide-header_incident-detail">
          {incident.description && (
            <div className="operation--text-title">
              <span>{incident.description}</span>
            </div>
          )}
          {incident.siteName && (
            <div className="operation--text-title">
              <span>
                {translate("incident.operatorGuideLocationLabel")}{" "}
                {incident.siteName}
              </span>
            </div>
          )}
          {incident.incidentTimestamp && (
            <div className="operation--text-subtitle">
              <DurationCounter targetTimestamp={incident.incidentTimestamp} />
            </div>
          )}
          {incident.resourceMappingName && (
            <div className="operation--text-subtitle">
              <span>
                {translate("incident.operatorGuideTriggeringDeviceLabel")}{" "}
                {incident.resourceMappingName}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OperatorGuideHeader;
