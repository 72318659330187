import React from "react";
import CommentIcon from "@mui/icons-material/CommentRounded";
import TouchAppIcon from "@mui/icons-material/TouchAppRounded";
import AttachmentIcon from "@mui/icons-material/AttachmentRounded";
import { ExecutionTaskType } from "../../../../model/OG/ExecutionTaskType";
import { TaskStepState } from "../../../../model/OG/TaskStepState";
import { OGExecutionTask } from "../../../../model/OG/OGExecutionTask";
import { OGExecutionComment } from "../../../../model/OG/OGExecutionComment";
import TaskUserBadge from "./taskUserBadge/TaskUserBadge";
import { AutomaticIncidentConfig } from "../../../../model/incident/AutomaticIncidentConfig";
import AIAlert from "../../../../assets/icons/AIAlert";

interface GenericExecutionTaskProps {
  task: OGExecutionTask | OGExecutionComment;
  taskState: TaskStepState;
  getBody: (automaticComment: boolean) => JSX.Element;
  automaticIncident?: AutomaticIncidentConfig;
}

const GenericExecutionTask = (props: GenericExecutionTaskProps) => {
  const { task, taskState, getBody, automaticIncident } = props;
  const isAnAutomaticComment = automaticIncident?.comments
    .map((comment) => comment.text)
    .includes((task as OGExecutionComment).comment);

  const getHistoricIcon = () => {
    let historicIcon: JSX.Element;
    switch (task?.type) {
      case ExecutionTaskType.Comment:
        historicIcon = isAnAutomaticComment ? (
          <AIAlert sx={{ fontSize: "2.2em" }} />
        ) : (
          <CommentIcon sx={{ fontSize: "2.2em" }} />
        );
        break;
      case ExecutionTaskType.ResourceInteraction:
        historicIcon = <TouchAppIcon sx={{ fontSize: "2.2em" }} />;
        break;
      case ExecutionTaskType.Attachment:
        historicIcon = <AttachmentIcon sx={{ fontSize: "2.2em" }} />;
        break;
      default:
        historicIcon = null;
        break;
    }

    if (historicIcon) {
      return (
        <div className="task-step-historic-info">
          <div className="task-step-avatar-wrapper">{historicIcon}</div>
        </div>
      );
    }

    return historicIcon;
  };

  return (
    <>
      {TaskStepState.COMPLETED === taskState ? (
        <div className="task-step-icon-wrapper">
          {!isAnAutomaticComment && <TaskUserBadge user={task.user} />}
          {getHistoricIcon()}
        </div>
      ) : (
        <div className="task-step-current-user-info" />
      )}
      <div data-cr="task-step-body" className="task-step-body">
        {getBody(isAnAutomaticComment)}
      </div>
    </>
  );
};

export default GenericExecutionTask;
